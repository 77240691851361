import { Component } from '@angular/core';

@Component({
  selector: 'has-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent {
  sections = [
    {
      title: 'Manufacturing',
      items: [
        'Pilot/Manufacturing Scale Process',
        'Planning/Logistics',
        'Process Validation (IQs, OQs, PQs, PPQs)',
        'Lean Six Sigma Methodologies',
        'Process Flow and Floor Layout',
        'Process Characterization',
        'Root Cause Analysis',
      ]
    },
    {
      title: 'Design Quality Assurance',
      items: [
        'New Product Introduction',
        'EU MDR 2017/745 Gap Analysis',
        'Design Control Management',
        'Quality Planning',
        'Risk Management File Remediation',
        'Technical File/Design Dossier/DHF Remediation',
        'Design Verification & Validation',
        'TMVs',
      ]
    },
    {
      title: 'Supplier Quality',
      items: [
        'Supplier Lifecycle Management',
        'Supplier Selection',
        'SCAR Management',
        'Supplier Part Process Qualification (PPAP+/SPPA)',
        'Supplier System Auditing',
        'Supplier Performance Monitoring and Improvement',
        'Relationship Management',
      ]
    },
    {
      title: 'QMS',
      items: [
        'CAPA Management',
        'Quality System Auditing',
        'Quality Process Improvement',
        'Quality/Regulatory Compliance',
        'FDA/Internal Audit Readiness',
        'Coplaints, MDRs, Adverse Events',
        'Production Nonconformances',
      ]
    }
  ];
}
