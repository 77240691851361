<mat-toolbar>
  <div fxLayout="row" fxLayoutAlign=" center" fxFlex>
    <div fxLayoutAlign="start center" fxFlex>
      <div class="logo-horizontal"></div>
    </div>
    <div fxLayoutAlign="end center" fxFlex>
      <div *ngIf="!mediaObserver.isActive('xs')">
        <button mat-button color="primary" (click)="scroll('about')">About</button>
        <button mat-button color="primary" (click)="scroll('services')">Services</button>
        <button mat-button color="primary" (click)="scroll('profile')">Profile</button>
        <button mat-button color="primary" (click)="scroll('contact')">Contact</button>  
      </div>
      <div *ngIf="mediaObserver.isActive('xs')">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item (click)="scroll('about')">
            <span class="text">About</span>
          </a>
          <a mat-menu-item (click)="scroll('services')">
            <span class="text">Services</span>
          </a>
          <a mat-menu-item (click)="scroll('profile')">
            <span class="text">Profile</span>
          </a>
          <a mat-menu-item (click)="scroll('contact')">
            <span class="text">Contact</span>
          </a>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
