import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'has-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild('about') about: ElementRef;
  @ViewChild('services') services: ElementRef;
  @ViewChild('profile') profile: ElementRef;
  @ViewChild('contact') contact: ElementRef;
  
  scroll(elementName: string) {
    // timeout to allow the menu on small devices to close before scrolling.
    setTimeout(() => this[elementName].nativeElement.scrollIntoView({ behavior: 'smooth' }), 0);
  }
}
