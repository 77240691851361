<div class="container" fxLayout="column" fxLayoutAlign="center center">
  <span class="title" ngClass.gt-xs="title-gt-xs">Services</span>
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center">
    <div *ngFor="let section of sections"
      class="section" ngClass.sm="section-sm" ngClass.gt-sm="section-gt-sm" fxLayout="column">
      <span class="section-title" ngClass.gt-xs="section-title-gt-xs">{{section.title}}</span>
      <span *ngFor="let item of section.items"
        class="section-item"  ngClass.gt-xs="section-item-gt-xs" ngClass.gt-md="section-item-gt-md">- {{item}}</span>
    </div>
  </div>
</div>
