<div class="container" fxLayout="row" fxLayout.gt-xs="column" fxLayoutAlign="center center" fxLayoutGap="50px">
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center center" fxLayoutGap="50px">
    <div class="image microplasty" ngClass.gt-xs="image-gt-xs"></div>
    <div class="image implants" ngClass.gt-xs="image-gt-xs"></div>
    <div class="image implant" ngClass.gt-xs="image-gt-xs"></div>
  </div>
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center center" fxLayoutGap="50px">
    <div class="image partial-knee" ngClass.gt-xs="image-gt-xs"></div>
    <div class="image rfb" ngClass.gt-xs="image-gt-xs"></div>
    <div class="image th" ngClass.gt-xs="image-gt-xs"></div>
  </div>
</div>
