<div class="container" fxLayout="row" fxLayoutAlign="center center"
  fxLayoutGap="10px" fxLayoutGap.gt-xs="50px">
  <div ngClass="image-container">
    <img src="../../assets/images/1503783004308.png">
  </div>
  <div fxLayout="column" fxLayoutAlign=" center">
    <span class="title">Contact us</span>
    <div fxLayoutGap="20px">
      <a mat-fab color="primary" href="https://www.linkedin.com/in/hosaemelbourne" target="_blank">
        <img src="../../assets/icons/linkedin.svg" class="icon">
      </a>
      <a mat-fab color="primary" href="mailto:hosae.melbourne@gmail.com">
        <img src="../../assets/icons/email.svg" class="icon">
      </a>
    </div>
  </div>
  <div ngClass="image-container">
    <img src="../../assets/images/Navigator_02.jpg">
  </div>
</div>
