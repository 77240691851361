<div class="container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.gt-xs="50px">
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.gt-xs="50px">
    <img class="image" ngClass.sm="image-sm" ngClass.gt-sm="image-gt-sm"
      src="../../assets/images/920x920.jpg">
    <img class="image" ngClass.sm="image-sm" ngClass.gt-sm="image-gt-sm"
      src="../../assets/images/Dental-Implants-1024x683.jpg">
  </div>
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.gt-xs="50px">
    <img class="image" ngClass.sm="image-sm" ngClass.gt-sm="image-gt-sm"
      src="../../assets/images/X0000_Bayer_Jetstream XC in vessel blades up.jpg">
    <img class="image" ngClass.sm="image-sm" ngClass.gt-sm="image-gt-sm"
      src="../../assets/images/stent.span.jpg">
  </div>
</div>
