import { Component, Output, EventEmitter } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'has-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Output() onScroll = new EventEmitter<string>();
  
  constructor(public mediaObserver: MediaObserver) { }

  scroll(elementName: string): void {
    this.onScroll.emit(elementName);
  }
}
