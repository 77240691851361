<has-header (onScroll)="scroll($event)"></has-header>
<div class="parallax-container">
  <!-- About -->
  <div #about class="parallax-wrapper parallax-image-main">
    <has-about></has-about>
  </div>

  <!-- Images 1 -->
  <div class="regular-wrapper">
    <has-images1></has-images1>
  </div>

  <!-- Services -->
  <div #services class="parallax-wrapper parallax-image-gloves">
    <has-services></has-services>
  </div>

  <!-- Images 2 -->
  <div class="regular-wrapper">
    <has-images2></has-images2>
  </div>

  <!-- Profile -->
  <div #profile class="parallax-wrapper parallax-image-stent">
    <has-profile></has-profile>
  </div>

  <!-- Contact -->
  <div #contact class="regular-wrapper regular-wrapper-short">
    <has-contact></has-contact>
  </div>

  <!-- Footer -->
  <div class="regular-wrapper footer">
    <has-footer></has-footer>
  </div>
</div>
