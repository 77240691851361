<div class="container" fxLayoutAlign="center center">
  <div fxLayout="column" fxLayout.gt-sm="row"
    fxLayoutGap="20px" fxLayoutGap.gt-xs="40px"
    fxLayoutAlign=" center" fxLayoutAlign.gt-sm="center">
    <img ngClass="profile-photo" ngClass.xs="profile-photo-xs" src="../../assets/images/profile.jpg">
    <span class="description" ngClass.xs="description-xs" ngClass.md="description-md">
      Hosae A. Melbourne, BSIE, MBA, ASQ CQA, ISO13485 Lead Auditor, SSGB, President,
      Senior Quality Engineering & Compliance Consultant for HAS Melbourne & Associates Inc.
      has over 20+ years Global Product/Process Development experience in Medical Device and
      Bio-Technology Industry committed to Quality, Safety and Patient’s Health. Technical
      leader with hands on experiences in Design & Manufacturing Quality and Manufacturing
      Process Engineering with a heavy focus on Quality System Compliance ensuring new and
      previously commercialized Medical Devices and Technologies are in compliance with
      FDA – 21 CFR Part 820, EN ISO 13485, EU MDR 2017/745, EN ISO 14971 and other regulations
      and international standards. Proven track record of project tasks execution and management
      resulting in the commercialization of numerous Class II and III Medical Devices.
      Hosae multilingual abilities in Dutch, English and Spanish is a valuable asset to the
      organization when dealing with clients in the international arena. He has lived abroad
      in South America and has conducted & completed project initiatives in Quality Compliance,
      Design Control, and Supplier Audits throughout the US, Latin America, Caribbean, and Europe.</span>  
  </div>
</div>
