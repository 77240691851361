<div class="container" fxLayout="column" fxLayout.gt-xs="row"
  fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.gt-xs="40px">
  <div class="logo"></div>
  <div fxLayout="column" fxLayoutAlign="start" fxLayoutAlign.xs=" center" fxLayoutGap="5px">
    <span class="heading" fxHide.xs>Contact</span>
    <span>Phone: (772) 812-8492</span>
    <span>Email: hosae.melbourne@gmail.com</span>
  </div>
  <div fxLayout="column" fxLayoutAlign=" center" fxLayoutGap="5px">
    <span class="heading" fxHide.xs>Connect</span>
    <div fxLayoutGap="10px">
      <a href="https://www.linkedin.com/in/hosaemelbourne" target="_blank">
        <img src="../../assets/icons/linkedin.svg" class="icon">
      </a>
      <a href="mailto:hosae.melbourne@gmail.com">
        <img src="../../assets/icons/email.svg" class="icon">
      </a>  
    </div>
  </div>
</div>
